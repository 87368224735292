<template>
  <section>
      <header class="mb-2">
          <b-card>
              <b-row>
                  <b-col cols="12">
                      <section class="d-flex justify-content-between">
                          <div>
                              <p><strong>No. Invoice</strong></p>
                              <p>{{ pb.no_invoice }}</p>
                          </div>
                          <!-- <div v-if="pb.diterima == 0">
                              <b-button @click="setuju" variant="primary">
                                  <span class="align-middle">Diterima</span>
                                  <feather-icon icon="CheckIcon" class="mr-1">
                                  </feather-icon>
                              </b-button>
                              <b-button @click="tolak" variant="primary">
                                  <span class="align-middle">Ditolak</span>
                                  <feather-icon icon="CheckIcon" class="">
                                  </feather-icon>
                              </b-button>
                          </div> -->
                          <!-- <div v-if="isCabang && pb.status == 1">
                              <b-button @click.prevent="$router.push({name: 'penyimpanan', query: {inv: pb.id}})" variant="primary">
                                  <span class="align-middle">Tempatkan Barang</span>
                                  <feather-icon icon="ChevronRightIcon" class="ml-1">
                                  </feather-icon>
                              </b-button>
                          </div> -->
                          <div v-if="isAdminGudang && pb.status == 1">
                              <b-button @click.prevent="batal()" variant="danger">
                                  <span class="align-middle">Batalkan Transaksi</span>
                                  <feather-icon icon="ChevronRightIcon" class="ml-1">
                                  </feather-icon>
                              </b-button>
                          </div>
                          <div v-if="isAdminGudang && pb.status == 0">
                              <b-button @click.prevent="$router.push(`/owner/pengiriman/edit/${$route.params.id}`)" variant="info">
                                  <span class="align-middle">Edit Pengiriman</span>
                                  <feather-icon icon="Edit2Icon" class="ml-1">
                                  </feather-icon>
                              </b-button>
                          </div>
                      </section>
                  </b-col>
                  <b-col md="3" sm="12">
                      <p><strong>Tanggal Pengiriman</strong></p>
                      <p>{{ humanDate(pb.tanggal) }}</p>
                  </b-col>
                  <b-col md="3" sm="12">
                      <p><strong>Gudang Tujuan</strong></p>
                      <p>{{ pb.to ? pb.to.nama_gudang : "-" }}</p>
                  </b-col>
                  <b-col md="2" sm="12">
                      <p><strong>Status</strong></p>
                      <p v-if="pb.status == 1"> <b-badge variant="success">{{ pb.status_ket.toUpperCase() }}</b-badge></p>
                      <p v-if="pb.status == 0"> <b-badge variant="warning">{{ pb.status_ket.toUpperCase() }}</b-badge></p>
                  </b-col>
                  <!-- <b-col md="4" sm="12">
                      <p><strong>Keterangan</strong></p>
                      <p>{{pb.keterangan ? pb.keterangan : 'kosong'}}</p>
                  </b-col> -->
              </b-row>
          </b-card>
      </header>

      <main>
          <b-card>
              <h5>Daftar Barang Yang Diterima</h5>
              <b-table striped hover :items="barangs" :fields="barangFields">
                  <template #cell(no)="row">
                      {{ (row.index + 1) }}
                  </template>
                  <!-- <template #cell(nama)="{item}">
                      {{ item.nama ? item.nama : '-' }}
                  </template>
                  <template #cell(satuan)="{item}">
                      {{ item.barang && item.barang.satuan ? item.barang.satuan.satuan : '-' }}
                  </template> -->
                  <template #cell(qty)="{item}">
                    {{ formatRupiah(item.qty) }}
                  </template>
                  <template #cell(approve)="{item}">
                    <b-button v-if="isCabang && item.diterima == 0" title="'Terima'" size="sm" rounded class="mr-1" variant="success"
                      @click.prevent="setuju(item)">
                      <feather-icon icon="CheckIcon" />
                      Diterima
                    </b-button>
                    <b-button v-if="isCabang && item.diterima == 0" title="'Tolak'" size="sm" rounded class="mr-1" variant="danger"
                      @click.prevent="tolak(item)">
                      <feather-icon icon="XIcon" />
                      Ditolak
                    </b-button>
                    <b-button v-if="isAdminGudang && item.diterima == 2" title="'Kembalikan barang ke penyimpanan'" size="sm" rounded class="mr-1" variant="info"
                      @click.prevent="kembalikan(item)">
                      <feather-icon icon="RefreshIcon" />
                      Kembalikan Barang ke Penyimpanan
                    </b-button>
                      <template v-if="item.diterima > 0">
                          <!-- <feather-icon icon="XIcon" /> -->
                          {{ item ? item.status.toUpperCase() : "-"  }}
                      </template>
                  </template>
              </b-table>
          </b-card>
      </main>
  </section>
</template>
<script>
  import {BRow, BCol, BCard,
  BBadge, BButton, BTable} from 'bootstrap-vue'
  export default {
      components: {
          BRow,
          BCol,
          BButton,
          BCard,
  BBadge,
          BTable
      },
      data: () => ({
          pb: {
              no_invoice: null,
              tanggal_invoice: null,
              tanggal_penerimaan: null,
              keterangan: null,
              selesai: null
          },
          barangFields: [
              {key: 'no', label: 'No', sortable: true},
            //   {key: 'no_container', label: 'No. Container', sortable: true},
            //   {key: 'no_seal', label: 'No. Seal', sortable: true},
              {key: 'nama', label: 'Nama Barang', sortable: true},
              {key: 'qty', label: 'Kuantitas', sortable: true},
              {key: 'satuan', label: 'Satuan', sortable: true},
              {key: 'approve', label: '#', sortable: true}
          ],
          barangs: []
      }),
      methods: {
          async getPb() {
              const pb = await this.$store.dispatch('pengiriman/getDataById', this.$route.params.id)
              this.pb = pb
              let rinci = pb.rincian
              rinci.forEach((rin) => {
        this.barangs.push({
          id: rin.id,
          nama: rin.barang.nama,
          satuan: rin.barang.satuan.satuan,
          qty : rin.qty,
          diterima : rin.diterima,
          status : rin.status,
        });
      });
          },
    async setuju(item) {
    //   const { data, index } = item;
    //   this.$store
    //     .dispatch("pengiriman/approve", 
    //       {
    //         pengiriman_rincian_id: item.id,
    //         status: 1,
    //       },
    //     )
    //     this.barangs = null
    //     this.getPb()

        this.$swal({
        title: "Anda yakin?",
        text: `Barang ini akan diterima dan disetujui`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        // const allowed = result.value;
        if (result.value) {
          // this.loading = true;
          this.$store
            .dispatch("pengiriman/approve", {
            pengiriman_rincian_id: item.id,
            status: 1,
          },)
            .then(() => {
              // this.loading = false;
              delete this.barangs
        // this.barangs = null
        this.displaySuccess({
          text: "Data berhasil diterima dan disetujui",
        });
        this.getPb()
            //   this.getPb();
            })
            .catch((e) => {
              // this.loading = false;
              this.displayError(e);
              return false;
            });
        }
      });
    },
    tolak(item) {
    //   const { data, index } = item;
    //   this.$store
    //     .dispatch("pengiriman/approve", 
    //       {
    //         pengiriman_rincian_id: item.id,
    //         status: 2,
    //       },
    //     )
    //     this.barangs = null
    //     this.getPb()
        
        this.$swal({
        title: "Anda yakin?",
        text: `Barang ini akan ditolak`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        // const allowed = result.value;
        if (result.value) {
          // this.loading = true;
          this.$store
            .dispatch("pengiriman/approve", {
            pengiriman_rincian_id: item.id,
            status: 2,
          },)
            .then(() => {
              // this.loading = false;
        // this.barangs = null
              delete this.barangs
        this.getPb()
              this.displaySuccess({
                text: "Data berhasil ditolak",
              });
            //   this.getPb();
            })
            .catch((e) => {
              // this.loading = false;
              this.displayError(e);
              return false;
            });
        }
      });
    },
    batal() {
      this.$swal({
        title: "Anda yakin?",
        text: `Data Pengiriman Barang ke Cabang ini akan dibatalkan`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        // const allowed = result.value;
        if (result.value) {
          // this.loading = true;
          this.$store
            .dispatch("pengiriman/batal", {
              pengiriman_id: this.$route.params.id,
              metode: 'transaksi',
            })
            .then(() => {
              // this.loading = false;
              delete this.barangs
              this.displaySuccess({
                text: "Data berhasil dibatalkan",
              });
              this.getPb();
            })
            .catch((e) => {
              // this.loading = false;
              this.displayError(e);
              return false;
            });
        }
      });
    },
    kembalikan(item) {
    //   const { data, index } = item;
    //   this.$store
    //     .dispatch("pengiriman/approve", 
    //       {
    //         pengiriman_rincian_id: item.id,
    //         status: 2,
    //       },
    //     )
    //     this.barangs = null
    //     this.getPb()
        
        this.$swal({
        title: "Anda yakin?",
        text: `Barang ini akan dikembalikan ke penyimpanan`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        // const allowed = result.value;
        if (result.value) {
          // this.loading = true;
          this.$store
            .dispatch("pengiriman/batal", {
            pengiriman_rincian_id: item.id,
            metode: "ditolak",
          },)
            .then(() => {
              // this.loading = false;
              delete this.barangs
        this.getPb()
              this.displaySuccess({
                text: "Data berhasil dikembalikan ke penyimpanan",
              });
            //   this.getPb();
            })
            .catch((e) => {
              // this.loading = false;
              this.displayError(e);
              return false;
            });
        }
      });
    },
        //   async getBarangPb() {
        //       const barangs = await this.$store.dispatch('pengiriman/getDataBarang', {
        //           penerimaan_id: this.$route.params.id
        //       })
        //       this.barangs = barangs
        //   },
      },
      async created() {
          this.getPb()
        //   this.getBarangPb()
      }
  }
</script>